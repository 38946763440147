@font-face {
  font-family: "Poppins";
  src: local("Poppins"),
    url(./assets/font/Poppins/Poppins-Regular.ttf),
      format("ttf");
  /* other formats include: 'woff2', 'truetype, 'opentype',
                            'embedded-opentype', and 'svg' */
}
@font-face {
  font-family: "PoppinsBold";
  src: local("PoppinsBold"),
    url(./assets/font/Poppins/Poppins-Bold.ttf),
      format("ttf");
  /* other formats include: 'woff2', 'truetype, 'opentype',
                            'embedded-opentype', and 'svg' */
}

/* src/index.css or src/App.css */
@tailwind base;
@tailwind components;
@tailwind utilities;



body,
html {
  font-family: "Poppins";
  margin: 0;
  padding: 0;
  scroll-behavior: smooth;
}

.maps, .dashboard-box, .recent-box {
  scrollbar-width: none !important;
}

.sign_page {
  background-image: url(./assets/images/bg.jpg);
  background-repeat: no-repeat;
  background-size: cover;
}

.box-shadow {
  box-shadow: 1px 5px 50px #e5e5e5;
}

.footer-bg {
  background-image: url(./assets/images/footerImg.png);
  background-repeat: no-repeat;
  background-size: cover;
}